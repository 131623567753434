<template>
  <div id="personalinfo">
    <div class="titleLine">
      <progress-circle-bar :value="progress" size= 50></progress-circle-bar>
      <div class="titleBox">
        <h1>{{$t(`lang.personalInformation`)}}</h1>
        <h3>{{$t(`lang.personalInformationText`)}}</h3>
      </div>
    </div>


    <div class="content">
      <div class="menu">
        <div class="title">{{$t(`lang.personalInformation`)}}</div>
        <menu-option-item>
          <template v-slot:title>{{$t(`lang.avatar`)}}</template>
          <template v-slot:value><img class="avatar" :src="personalProfile.avatar"></template>
          <template v-slot:btn>
            <el-upload
              :on-success="onUploadSuccessImg"
              :multiple="false"
              :action="avatarUrl"
              :data="{module:'avatar'}"
              :limit="1"
              class="uploadbtn">
              <svg-icon name="edit" color="#fff"></svg-icon>
            </el-upload>
          </template>
        </menu-option-item>
        <menu-option-item>
          <template v-slot:title>{{$t(`lang.nickName`)}}</template>
          <template v-slot:value><div v-if="isNickName">{{personalProfile.nickName}}</div><div v-if="!isNickName"><input type="text" v-model="personalProfile.nickName"></div></template>
          <template v-slot:btn><svg-icon name="edit" color="#fff" @click="changeNickName()"></svg-icon></template>
        </menu-option-item>
        <menu-option-item>
          <template v-slot:title>{{$t(`lang.birth`)}}</template>
          <template v-slot:value>{{personalProfile.birth}}</template>
          <template v-slot:btn><svg-icon name="edit" color="#fff"></svg-icon></template>
        </menu-option-item>
        <menu-option-item>
          <template v-slot:title>{{$t(`lang.gender`)}}</template>
          <template v-slot:value>
            <div class="genderBox">
              <div class="gender">
                <span class="man" v-if="!personalProfile.gender"><h4>{{$t(`lang.male`)}}</h4></span>
                <span class="woman" v-if="personalProfile.gender"><h4>{{$t(`lang.female`)}}</h4></span>
                <input type="checkbox" class="checkGender" @click="changeGender" v-model="personalProfile.gender">
              </div>
            </div>
          </template>
        </menu-option-item>
        <menu-option-item>
          <template v-slot:title>{{$t(`lang.phone`)}}</template>
          <template v-slot:value>{{personalProfile.phone}}Not Opened</template>
          <template v-slot:btn><svg-icon name="toright" color="#fff"></svg-icon></template>
        </menu-option-item>
        <menu-option-item>
          <template v-slot:title>{{$t(`lang.email`)}}</template>
          <template v-slot:value>{{personalProfile.email}}</template>
          <template v-slot:btn><svg-icon name="toright" color="#fff"></svg-icon></template>
        </menu-option-item>
      </div>
      <div class="menu">
        <div class="title">{{$t(`lang.emailSetting`)}}</div>
          <div class="textTitle"><h2>{{$t(`lang.commonNotice`)}}</h2></div>
          <menu-option-item height= "40" :textStart= textStart>
            <template v-slot:title><input class="checkbox" type="checkbox" v-model="personalProfile.acceptAdvertising" @click="checkAcceptAdvertising"/></template>
            <template v-slot:value>{{$t(`lang.commonNotice1`)}}</template>
          </menu-option-item>
          <menu-option-item height= "40" :textStart= textStart>
            <template v-slot:title><input class="checkbox" type="checkbox" v-model="personalProfile.focusPriceNotice" @click="checkFocusPriceNotice"/></template>
            <template v-slot:value>{{$t(`lang.commonNotice2`)}}</template>
          </menu-option-item>
          <menu-option-item height= "40" :textStart= textStart>
            <template v-slot:title><input class="checkbox" type="checkbox" v-model="personalProfile.acceptWaveWarning" @click="checkAcceptWaveWarning"/></template>
            <template v-slot:value>{{$t(`lang.commonNotice3`)}}</template>
          </menu-option-item>
          <div class="line"></div>
          <div class="textTitle"><h2>{{$t(`lang.securityNotice`)}}</h2></div>
          <menu-option-item height= "40" :textStart= textStart>
            <template v-slot:title><input class="checkbox" type="checkbox" v-model="personalProfile.loginNotice" @click="checkloginNotice"/></template>
            <template v-slot:value>{{$t(`lang.securityNotice1`)}}</template>
          </menu-option-item>
          <menu-option-item height= "40" :textStart= textStart>
            <template v-slot:title><input class="checkbox" type="checkbox" v-model="personalProfile.exceptionLogin" @click="checkexceptionLogin"/></template>
            <template v-slot:value>{{$t(`lang.securityNotice2`)}}</template>
          </menu-option-item>
          <div class="line"></div>
          <div class="textTitle"><h2>{{$t(`lang.tradingNotice`)}}</h2></div>
          <menu-option-item height= "40" :textStart= textStart>
            <template v-slot:title><input class="checkbox" type="checkbox" v-model="personalProfile.tradingNotice" @click="checktradingNotice"/></template>
            <template v-slot:value>{{$t(`lang.tradingNotice1`)}}</template>
          </menu-option-item>
          <menu-option-item height= "40" :textStart= textStart>
            <template v-slot:title><input class="checkbox" type="checkbox" v-model="personalProfile.acceptBillNotice" @click="checkacceptBillNotice"/></template>
            <template v-slot:value>{{$t(`lang.tradingNotice2`)}}</template>
          </menu-option-item>
      </div>
    </div>
    <div class="btnBox">
      <btn-submit class="btnSave" color= "blue" @click="back">{{$t(`lang.back`)}}</btn-submit>
      <btn-submit class="btnSave" color= "red" @click="updatePersonalFile">{{$t(`lang.save`)}}</btn-submit>
    </div>
  </div>
  <!-- <el-upload
        :on-success="onUploadSuccessImg"
        :multiple="false"
        :action="avatarUrl"
        :data="{module:'avatar'}"
        :limit="1"
        class="uploadbtn">
        <svg-icon name="edit" color="#fff"></svg-icon>
      </el-upload> -->
</template>

<script>
import ProgressCircleBar from '@/components/progressBar/ProgressCircleBar.vue'
import MenuOptionItem from '../components/MenuOptionItem.vue'
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue'
import cookie from 'js-cookie'
import { getPersonalSetting,updatePersonalSetting,removeImg } from "@/axios/api/setting"
import BtnSubmit from '@/components/button/BtnSubmit.vue'
export default {
  name:'PersonalInfo',
  components: {
    ProgressCircleBar,
    MenuOptionItem,
    SvgIcon,
    BtnSubmit
  },
  data() {
    return {
      personalProfile: '',
      progress: 10,
      textStart: true,
      isNickName: true,
      avatarUrl:"http://13.114.63.198:9119/admin/oss/file/upload"
    }
  },
  mounted(){
    getPersonalSetting().then(res=>{
      this.personalProfile = res.data.personalSetting
    }),
    this.progress = 100/10
  },
  methods:{
    back(){
      this.$router.go(-1)
    },
    changeNickName(){
      this.isNickName = !this.isNickName
    },
    updatePersonalFile(){
      updatePersonalSetting(this.personalProfile).then(res=>{
        cookie.set('userInfo',res.data.userInfo)
        window.location.href = '/usercenter'
      })
    },
    checkAcceptAdvertising(){
      this.personalProfile.acceptAdvertising = !this.personalProfile.acceptAdvertising
    },
    checkFocusPriceNotice(){
      this.personalProfile.focusPriceNotice = !this.personalProfile.focusPriceNotice
    },
    checkAcceptWaveWarning(){
      this.personalProfile.acceptWaveWarning = !this.personalProfile.acceptWaveWarning
    },
    checkloginNotice(){
      this.personalProfile.loginNotice = !this.personalProfile.loginNotice
    },
    checkexceptionLogin(){
      this.personalProfile.exceptionLogin = !this.personalProfile.exceptionLogin
    },
    checktradingNotice(){
      this.personalProfile.tradingNotice = !this.personalProfile.tradingNotice
    },
    checkacceptBillNotice(){
      this.personalProfile.acceptBillNotice = !this.personalProfile.acceptBillNotice
    },
    changeGender(){
      this.personalProfile.gender = !this.personalProfile.gender
    },
    onUploadSuccessImg(response){
      removeImg(this.personalProfile.avatar)
      this.onUploadSuccess(response)
    },
    onUploadSuccess(response){
      if(response.code==20000){
        this.personalProfile.avatar = response.data.url
        console.log(response.data.url)
      } else{
        this.$message.error(response.message);
      }
    }
  },
}
</script>

<style scoped>
.uploadbtn{
  border: none;
  background: none;
  opacity: 1;
  position: absolute;
  width: 30px;
  cursor: pointer;
  height: 30px;
  z-index: 11;
  margin-top: -12px;
}
.titleLine{
  position: relative;
  height: 140px;
  width: 100%;
  display: flex;
  color: #fff;
}
.titleBox{
  margin-left: 60px;
  width: 50%;
}
h3{
  color: rgb(187, 187, 187);
}
h1{
  font-size: 32px;
}
h2{
  font-size: 26px;
}
.content{
  width: 100%;
  display: flex;
  height: 100%;
  max-height: 500px;
  min-height: 420px;
}
.menu{
  width: 40%;
  margin-right: 140px;
}
.title{
  width: 100%;
  height: 40px;
  background: #2C2D30;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  line-height: 40px;
  padding-left: 40px;
  color: #fff;
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 10px;
}
.avatar{
  height: 50px;
  width: 50px;
  border-radius: 25px;
  overflow: hidden;
}
.checkbox{
  height: 22px;
  width: 22px;
}
.btnBox{
  margin-top: 20px;
  display: flex;
}
input{
  height: 30px;
  width: 300px;
  font-size: 18px;
}
.genderBox{
  position: relative;
  display: flex;
}
.checkGender{
  position: relative;
  width: 200px;
  height: 40px;
  -webkit-appearance: none;
  background: #03a9f4;
  outline: none;
  border-radius: 10px;
  transition: .6s;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
  cursor: pointer;
}
.checkGender:checked{
  background: #a32e2e;
}
.checkGender::before{
  content: '';
  position: absolute;
  width: 100px;
  height: 40px;
  border-radius: 10px;
  top: 0;
  left: 0;
  background: #fff;
  transition: .6s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
}
.checkGender:checked::before{
  left: 100px;
}

.woman{
  position: absolute;
  z-index: 1;
  top: 8px;
  right: 20px;
  pointer-events: none;
  transition: .6s;
}
.man{
  position: absolute;
  left: 26px;
  z-index: 1;
  top: 8px;
  pointer-events: none;
  font-weight: bolder;
  transition: .6s;
}
.man h4{
  color: rgb(15, 15, 15);
}
.woman h4{
  color: rgb(17, 17, 17);
}
.textTitle{
  color: #2EC5CE;
  margin-left: 40px;
  margin-bottom: 6px;
}
.line{
  width: 105%;
  height: 1px;
  background: #2EC5CE;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
